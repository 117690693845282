import React, { useState, useEffect, useCallback } from "react";
import Login from "./view/Login";
import Home from "./view/Home";
import { ScreenLoader } from "./components/ScreenLoader";
import { ServerFacade } from "./service/ServerFacade";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState("");

  const handleLogin = (username) => {
    setUser(username);
    setIsLoggedIn(true);
    setIsLoading(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUser("");
  };

  const handleAuthtokenValidation = useCallback(async () => {
    setIsLoading(true);
    let response;

    try {
      let serverFacade = new ServerFacade();
      response = await serverFacade.validateToken();

      if (!response) {
        throw(Error('Error connecting to server.'));
      }

    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      if (response.success) {
        localStorage.setItem('authToken', response.authToken);
        handleLogin(response.user);
      }
    }
  }, []);

  useEffect(() => { 
    handleAuthtokenValidation();
  }, [handleAuthtokenValidation]);

  return (
    <div>
      {isLoading ? (
        <ScreenLoader className="center" /> // Render the loader component while isLoading is true
      ) : isLoggedIn ? (
        <Home user={user} onLogout={handleLogout} />
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;
