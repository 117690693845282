import axios from "axios";

export class ServerFacade {
  aws_url = "https://67o64rpj3m.execute-api.us-west-2.amazonaws.com/production";

  /**
   * This method is used to create a new user.
   * @param {*} username 
   * @param {*} password 
   * @returns the response from the server
   */
  login = async (username, password) => {
    const data = {
      email: username,
      password: password,
    };
    return await this.postRequest(this.aws_url + "/login", data);
  };

  /**
   * This method is used to logout the user. It requests that the server delete the authToken.
   * @returns the response from the server
   */
  logout = async () => {
    const data = {
      authToken: this.getAuthToken(),
    };
    return await this.postRequest(this.aws_url + "/logout", data);
  };

  /**
   * This method is used to create a new user.
   * @param {*} username
   * @param {*} password
   * @returns the response from the server
  */
  changePassword = async (oldPassword, newPassword) => {
    const data = {
      authToken: this.getAuthToken(),
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    return await this.postRequest(this.aws_url + "/changepassword", data);
  };

  /**
   * This method is used to validate the authToken. It will return true in the server response
   * if the authToken is valid.
   * @returns the response from the server
   */
  validateToken = async () => {
    const data = {
      authToken: localStorage.getItem("authToken"),
    };
    return await this.postRequest(this.aws_url + "/validatetoken", data);
  };

  /**
   * This method is used to get a JSON report from the server.
   * @returns the response from the server
   */
  getReportURL = async (timestamp, email) => {
    const data = {
      authToken: this.getAuthToken(),
      email: email,
      timestamp: timestamp,
    };
    return await this.postRequest(this.aws_url + "/getjsonreport", data);
  };

  getReportImageURL = async (timestamp, version, imageType, imageIndex) => {
    const data = {
      authToken: this.getAuthToken(),
      timestamp: timestamp,
      version: version,
      imageType: imageType,
      imageIndex: imageIndex,
    };
    console.log(data)
    return await this.postRequest(this.aws_url + "/getreportimage", data);
  };

  /**
   * This method is used to get a list of reports from the server. It doesn't get the
   * actual reports, just a list of data describing the reports. 
   * 
   * This function uses pagination. If this is the first request, then lastTimestamp should be 0. 
   * The most recent reports will be returned. If the next reports are wanted, then the lastTimestamp
   * should be the timestamp of the last report in the list. This will return the next 10 reports. The
   * response will contain a "hasMorePages" value that is set to true or false depending on if there are
   * more reports to be returned.
   * @param {*} lastTimestamp - The timestamp of the last report in the list. 0 if this is the first request.
   * @returns the response from the server
   */
  getReports = async (lastTimestamp) => {
    let LIMIT = "50";
    const data = {
      authToken: this.getAuthToken(),
      limit: LIMIT,
      lastItem: lastTimestamp,
    };
    return await this.postRequest(this.aws_url + "/getreports", data);
  };

  /**
   * Used to set a report as opened. This will set the "opened" value to true in the database.
   * 
   * @param {*} authToken - The authToken of the user.
   * @param {*} timestamp - The timestamp of the report to be set as opened.
   * @returns the response from the server
   */
  setReportOpened = async (timestamp) => {
    const data = {
      authToken: this.getAuthToken(),
      timestamp: timestamp,
    };
    return await this.postRequest(this.aws_url + "/markopened", data);
  };

  /**
   * This method is used to make a generic POST request to the server.
   * @param {*} url
   * @param {*} data
   * @returns the response from the server
   */
  postRequest = async (url, data) => {
    try {
      const response = await axios.post(url, JSON.stringify(data));

      return response.data; // Return the response if needed
    } catch (error) {
      // Handle error - This seems good enough for now
      console.error(error);
      // We could also throw an error or return a specific value in case of failure
    }
  };

  /**
   * This method is used to get the authToken from the localStorage.
   * @returns the authToken
   */
  getAuthToken = () => {
    return localStorage.getItem("authToken");
  };
}
