import React, { useState } from "react";
import gimme_powered_by_bellu_labs from "../images/partner_logos/gimme_powered_by_bellu_labs.png";
import renew_hair_loss_center_powered_by_bellu_labs from "../images/partner_logos/renew_hair_loss_center_powered_by_bellu_labs.png";
import black_full_logo from "../images/black_full_logo.png";
import profile_icon from "../images/profile_icon.svg";
import { UserMenu } from "./UserMenu";

/**
 * This component is used to display the header of the application.
 * @param {object} param0.user - The user object
 * @param {function} param0.handleLogout - Function to log the user out
 * @param {function} param0.handleChangePassword - Function to change the user's password
 * @param {function} param0.handleGoHome - Function to return the user to the home page
 * @returns header component
*/
const Header = ({ user, handleLogout, handleChangePassword, handleGoHome}) => {
  const [profileHover, setProfileHover] = useState(false);

  const handleChangePasswordFromMenu = () => {
    setProfileHover(false);
    handleChangePassword();
  };

  const handleGoHomeFromMenu = () => {
    setProfileHover(false);
    handleGoHome();
  };

  const toggleProfileHover = () => {
    setProfileHover(!profileHover);
  };

  const handleProfileHover = () => {
    setProfileHover(true);
  };

  const handleProfileLeave = () => {
    setProfileHover(false);
  };

  const getLogo = () => {
    switch (user.userType) {
      case "GIMME":
        return (
          <img
            id="GimmePoweredByBelluLabsLogo"
            className="logo1"
            src={gimme_powered_by_bellu_labs}
            alt="Gimme Powered By Bellu Labs"
          />
        );
      case "RENEW_HAIR_LOSS_CENTER":
        // Return the logo for other user type 1
        return (
          <img
            id="RenewHairLossCenterPoweredByBelluLabsLogo"
            className="logo1"
            src={renew_hair_loss_center_powered_by_bellu_labs}
            alt="Renew Hair Loss Center Powered By Bellu Labs"
          />
        );
      default:
        // Return a default logo if no user type matches
        return (
          <img
            id="BelluLabsLogo"
            className="logo1"
            src={black_full_logo}
            alt="Bellu Labs"
          />
        );
    }
  };

  return (
    <div>
      <header>
        <div>{/* Empty for spacing */}</div>
        <div className="center">{getLogo()}</div>
        <div
          className="profile-icon"
          onMouseEnter={handleProfileHover}
          onMouseLeave={handleProfileLeave}
          onClick={toggleProfileHover}>
          <img src={profile_icon} alt="Profile Icon" />
        </div>
      </header>
      {profileHover ? (
        <div
          onMouseEnter={handleProfileHover}
          onMouseLeave={handleProfileLeave}>
          <UserMenu onLogout={handleLogout} onChangePassword={handleChangePasswordFromMenu} onGoHome={handleGoHomeFromMenu}/>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
