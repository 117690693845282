import React from "react";

/**
 * A custom user menu component
 * @param {*} param0 the props passed to the component
 * @returns the user menu component
 */
export const UserMenu = ({ onLogout, onChangePassword, onGoHome }) => {
  return (
    <div className="dropdown-menu">
      <ul>
        <li onClick={onGoHome}>Home</li>
        <li onClick={onChangePassword}>Change Password</li>
        <li onClick={onLogout}>Logout</li>
      </ul>
    </div>
  );
};
