import React, { useState } from "react";
import "../css/Login.css";
import { Loader } from "../components/Loader";
import { ServerFacade } from "../service/ServerFacade";
import full_logo from "../images/black_full_logo.png";

/**
 * This component is used to display the login page of the application.
 * @param {*} param0 - Function to log the user in
 * @returns login component
 */
const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async () => {
    setIsLoading(true);
    let response;

    try {
      let serverFacade = new ServerFacade();
      response = await serverFacade.login(username, password);

      if (!response) {
        throw Error("Error connecting to server.");
      }
    } catch (error) {
      // Error occurred during login
      setErrorMessage("An error occurred. Please try again later.");
    } finally {
      if (response.success) {
        localStorage.setItem("authToken", response.authToken);
        onLogin(response.user);
      } else {
        setErrorMessage("Username or password was incorrect");
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="center login-page">
      <form className="login-container">
        <img className="logo_login" src={full_logo} alt="BelluLabs Logo" />
        <div className="input-container">
          <input
            type="email"
            placeholder="Email"
            value={username}
            onChange={(e) => setUsername(e.target.value.toLowerCase())}
          />
        </div>
        <div className="input-container">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <input
              value="Login"
              className="login-button"
              onClick={handleLogin}
              type="submit"
            />
          </div>
        )}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
};

export default Login;
