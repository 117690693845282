import "../css/Loader.css";
import React from 'react';

/**
 * A custom loader component
 * @param {*} props the props passed to the component
 * @returns the loader component
 */
export const Loader = (props) => {
  return (
    <div className="loading">
      <span className="loader">
        <span className="loader-inner"></span>
      </span>
    </div>
  );
};
