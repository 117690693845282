import "../css/Home.css";
import React, { useState } from "react";
import { ServerFacade } from "../service/ServerFacade";
import Header from "../components/Header";
import ChangePasswordForm from "../components/ChangePasswordForm";
import ReportsView from "./ReportsView";

/**
 * This component is used to display the home page of the application. This is where everything
 * is displayed. The home page is navigated by using the header, which is a header component.
 * @param {*} param0.onLogout - Function to log the user out
 * @param {*} param0.user - The user object
 * @returns home component
 */
const Home = ({ onLogout, user }) => {
  const [changingPassword, setChangingPassword] = useState(false);
  const [focusedReport, setFocusedReport] = useState(-1);

  const handleLogout = () => {
    let serverFacade = new ServerFacade();
    serverFacade.logout();
    onLogout();
  };

  const handleChangePassword = () => {
    setChangingPassword(true);
  };

  const handleGoHome = () => {
    setFocusedReport(-1);
    setChangingPassword(false);
  };

  const handleReportClick = (report) => {
    setFocusedReport(report);
  };

  return (
    <div className="home">
      <div className="home-header">
        <Header
          user={user}
          handleLogout={handleLogout}
          handleChangePassword={handleChangePassword}
          handleGoHome={handleGoHome}
        />
      </div>
      <div className="home-content">
        {changingPassword ? (
          <ChangePasswordForm
            onReturnHome={handleGoHome}
            onLogout={handleLogout}
            email={user.email}
          />
        ) : (
          <ReportsView focusedReport={focusedReport} setReportIndex={handleReportClick}/>
        )}
      </div>
    </div>
  );
};

export default Home;
