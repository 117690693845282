import React, { Component } from "react";
import StandardReport from "./StandardReport";
import "../css/ReportWithMenu.css";
import back_arrow from "../images/right_arrow.png";
import StarterReport from "./StarterReport";

/**
 * Component that displays a report that has a menu.
 */
class ReportWithMenu extends Component {
  constructor(props) {
    super(props);
    this.tabs = [];
    this.isInitialMount = true;
    this.state = {
      showMobileMenu: false,
    };
  }

  static tabList = document.getElementsByClassName("react-tabs__tab");

  componentDidMount() {
    // Add event listeners to tabs
    this.tabList = document.getElementsByClassName("react-tabs__tab");
    if (this.isInitialMount) {
      for (let i = 0; i < this.tabList.length; i++) {
        this.tabList[i].addEventListener("click", this.toggleMenu.bind(this));
      }
    }
    this.isInitialMount = false;
  }

  componentWillUnmount() {
    // Remove event listeners from tabs
    for (let i = 0; i < this.tabList.length; i++) {
      this.tabList[i].removeEventListener("click", this.toggleMenu.bind(this));
    }
  }

  toggleMenu() {
    let mainMenu = document.getElementsByClassName(
      "report-menu-control-container"
    );
    // check screen size
    if (window.innerWidth < 768) {
      if (this.state.showMobileMenu) {
        this.setState({ showMenu: false });
        // console.log("This is so true");
      } else {
        this.setState({ showMenu: true });
        // console.log("This is so false");
      }

      mainMenu[0].classList.toggle("menu-show");
    }
    else {
      if (this.state.showMenu) {
        this.setState({ showMenu: false });
        mainMenu[0].classList.toggle("menu-show");
      }
    }
  }

  renderReport = (showMenu) => {
    // console.log(showMenu);
    // console.log("Data:" + this.props.data);
    // console.log(this.props.data.report_type);
    let reportType = this.props.data.report_type;
    // console.log("Report type: " + reportType);

    switch (reportType) {
      case "standard":
        return (
          <StandardReport
            data={this.props.data}
            email={this.props.email}
            timestamp={this.props.timestamp}
            version={this.props.version}
          />
        );
      case "starter":
        return (
          <StarterReport
            data={this.props.data}
            email={this.props.email}
            timestamp={this.props.timestamp}
            version={this.props.version}
          />
        )
      // Add more cases for other report types as needed
      default:
        return (
          <div>
            No Report Content Available. Please contact websupport@bellu.com.
          </div>
        );
    }
  };

  render() {
    return (
      <div className="report-menu-control-container">
        <div onClick={this.toggleMenu.bind(this)} className="menu-icon">
          <svg viewBox="0 0 60 60">
            <line x1="5px" y1="30px" x2="55px" y2="30px" />
            <line x1="5px" y1="30px" x2="55px" y2="30px" />
            <line x1="15px" y1="30px" x2="45px" y2="30px" />
            <line x1="15px" y1="30px" x2="45px" y2="30px" />
            <line x1="5px" y1="30px" x2="55px" y2="30px" />
          </svg>
        </div>
        <button onClick={this.props.returnToReports}>
          <div>
            <img alt="back arrow" src={back_arrow} />
            <p>All Reports</p>
            
          </div>
        </button>
        <div className="report-container">
          {this.renderReport(this.state.showMobileMenu)}
        </div>
      </div>
    );
  }
}

export default ReportWithMenu;
