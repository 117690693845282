import React, { useState } from "react";
import "../css/ChangePasswordForm.css";
import { ServerFacade } from "../service/ServerFacade";
import { Loader } from "../components/Loader";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * This component is used to change the password of a user. 
 * @param {function} param0.onLogout - Function to log the user out
 * @param {function} param0.onReturnHome - Function to return the user to the home page.
 * This is used if the user cancels the change or if the change is successful.
 * @param {string} param0.email - The email of the user
 * @returns form component to change the user's password
 */
const ChangePasswordForm = ({ onLogout, onReturnHome, email }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isPasswordValidLength, setIsPasswordValidLength] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordChangeHappened, setPasswordChangeHappened] = useState(false);

  const handleSubmitHover = () => {
    setIsOldPasswordVisible(false);
    setIsNewPasswordVisible(false);
    setIsConfirmPasswordVisible(false);
  };

  const handleChange = (e) => {
    setPasswordChangeHappened(false);
    const { name, value } = e.target;
    setMessage(""); // Clear message when user starts typing

    if (name === "newPassword") {
      setNewPassword(value);
      if (value !== confirmPassword) {
        setPasswordsMatch(false);
      } else {
        setPasswordsMatch(true);
      }

      if (value.length >= 12) {
        setIsPasswordValidLength(true);
      } else {
        setIsPasswordValidLength(false);
      }
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      if (value !== newPassword) {
        setPasswordsMatch(false);
      } else {
        setPasswordsMatch(true);
      }
    } else if (name === "oldPassword") {
      setOldPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    setIsNewPasswordVisible(false);
    e.preventDefault();

    setMessage(""); // Clear message when user submits form
    setIsLoading(true); // Set loading state to true

    let response;

    try {
      let serverFacade = new ServerFacade();
      response = await serverFacade.changePassword(oldPassword, newPassword);

      if (!response) {
        throw Error("Error connecting to server.");
      }
    } catch (error) {
      // Error occurred during changin password
      setMessage(
        "An error occurred. If this happens again, please contact websupport@bellu.com for support."
      );
    } finally {
      if (response.success) {
        setMessage("Password changed successfully");
        setPasswordChangeHappened(true);
      } else if (response.message.includes("[Unauthorized]")) {
        onLogout();
      } else if (response.message.includes("[Bad Request] Invalid password")) {
        setMessage("The password you provided was incorrect.");
      } else {
        setMessage(
          "An unknown error occurred. If this happens again, please contact websupport@bellu.com for support."
        );
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="change-password-form-container">
      <div className="change-password-form">
        <h1>Change Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email:
            </label>
            <div className="change-password-input-container">
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                disabled
                onChange={handleChange}
                className="form-input"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="oldPassword" className="form-label">
              Old Password:
            </label>
            <div className="change-password-input-container">
              <input
                type={isOldPasswordVisible ? "text" : "password"}
                autoCorrect="off"
                autoCapitalize="none"
                id="oldPassword"
                name="oldPassword"
                value={oldPassword}
                onChange={handleChange}
                className="form-input"
              />
              <div
                className="password-visibility-icon center"
                onClick={() => setIsOldPasswordVisible(!isOldPasswordVisible)}>
                {isOldPasswordVisible ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="newPassword" className="form-label">
              New Password:
            </label>
            <div className="change-password-input-container">
              <input
                type={isNewPasswordVisible ? "text" : "password"}
                autoCorrect="off"
                autoCapitalize="none"
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                className={`form-input ${
                  isPasswordValidLength ? "valid" : "invalid"
                }`}
              />
              <div
                className="password-visibility-icon center"
                onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}>
                {isNewPasswordVisible ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password:
            </label>
            <div className="change-password-input-container">
              <input
                type={isConfirmPasswordVisible ? "text" : "password"}
                autoCorrect="off"
                autoCapitalize="none"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
                className="form-input"
              />
              <div
                className="password-visibility-icon center"
                onClick={() =>
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                }>
                {isConfirmPasswordVisible ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </div>
            </div>
            <div className="validity-message">
              {!isPasswordValidLength ? (
                <div className="invalid-message">
                  <span className="invalid-icon">&#10005;</span>
                  <span className="invalid-text"> At least 12 characters</span>
                </div>
              ) : (
                <div className="valid-message">
                  <span className="valid-icon">&#10003;</span>
                  <span className="valid-text"> At least 12 characters</span>
                </div>
              )}
              {!passwordsMatch ? (
                <div className="invalid-message">
                  <span className="invalid-icon">&#10005;</span>
                  <span className="invalid-text"> Passwords must match</span>
                </div>
              ) : (
                <div className="valid-message">
                  <span className="valid-icon">&#10003;</span>
                  <span className="valid-text"> Passwords must match</span>
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            {passwordChangeHappened || isLoading ? (
              <div></div>
            ) : (
              <div className="change-password-input-container">
                <button
                  type="submit"
                  onMouseEnter={handleSubmitHover}
                  disabled={
                    !isPasswordValidLength || isLoading || !passwordsMatch
                  }
                  className="change-password-button">
                  Change Password
                </button>
              </div>
            )}
          </div>
          {message && (
            <div className="form-message-container">
              <p className="form-message">{message}</p>
            </div>
          )}
          <div className="center">{isLoading && <Loader />} </div>
        </form>
        {passwordChangeHappened && (
          <button
            className="home-button change-password-button"
            onClick={onReturnHome}>
            Go Home
          </button>
        )}
      </div>
    </div>
  );
};

export default ChangePasswordForm;
