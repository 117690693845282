import { Loader } from "./Loader";
import React from 'react';
/**
 * A custom screen loader component
 * @param {*} props the props passed to the component
 * @returns the screen loader component
*/
export const ScreenLoader = (props) => {
  return (
    <div className="center full-screen">
      <Loader />
    </div>
  );
};
