import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../css/StandardReport.css";
import { ServerFacade } from "../service/ServerFacade";
import hair_anatomy_diagram from "../images/hair_anatomy_diagram.png";
import tab_background from "../images/report_images/report_background_364.jpg";
import welcome_foreground from "../images/report_images/welcome_foreground.jpg";
import products_foreground from "../images/report_images/products_foreground.jpg";
import lifestyle_foreground from "../images/report_images/lifestyle_foreground.jpg";
import microscope_foreground from "../images/report_images/microscope_foreground.png";
import facts_foreground from "../images/report_images/facts_foreground.jpg";

/**
 * StandardReport is a component that displays the standard report for a user.
 * It is composed of 5 tabs: Welcome, Product Recommendations, Lifestyle Recommendations,
 * Under the Microscope, and Facts and Figures.
 */
class StandardReport extends Component {
  constructor(props) {
    super(props);
    this.isInitialMount = true;
    this.state = {
      dials: [],
      desktopGraphReference1: "",
      desktopGraphReference2: "",
      mobileGraphReference1: "",
      mobileGraphReference2: "",
      graphError: false,
      isMobile: false,
    };
  }

  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  async componentDidMount() {
    // set event listener for screen size
    if (this.isInitialMount) {
      window.addEventListener("resize", this.handleResize);
    }
    this.handleResize();
    this.isInitialMount = false;
    let serverFacade = new ServerFacade();
    let tempDials = [];
    let NUM_DIALS = 6;
    try {
      // get dials
      for (let i = 1; i <= NUM_DIALS; i++) {
        await serverFacade
          .getReportImageURL(
            this.props.timestamp,
            this.props.version,
            "dials",
            i
          )
          .then((data) => {
            if (data) {
              if (data.success === false) {
              } else {
                tempDials.push(data.url);
              }
              this.setState({ dials: tempDials });
            }
          });
      }
      // get graphs
      let graphURLs = [];
      for (let i = 1; i <= 4; i++) {
        graphURLs.push(await this.getGraphURL(i));
      }
      console.log(graphURLs)
      if (graphURLs.length === 4) {
        this.setState({
          desktopGraphReference1: graphURLs[0],
          desktopGraphReference2: graphURLs[1],
          mobileGraphReference1: graphURLs[2],
          mobileGraphReference2: graphURLs[3],
        });
      } else {
        this.setState({ graphError: true });
      }
    } catch (error) {}
  }

  getGraphURL = async (graphNumber) => {
    let serverFacade = new ServerFacade();
    let tempStrandGraph = "";
    await serverFacade
      .getReportImageURL(
        this.props.timestamp,
        this.props.version,
        "charts",
        graphNumber
      )
      .then((data) => {
        if (data) {
          if (data.success === false) {
            // TODO: handle error
          } else {
            tempStrandGraph = data.url;
          }
        }
      });
    return tempStrandGraph;
  };

  render() {
    const shampooItems = this.props.data.products.shampoos.map((shampoo) => (
      <div className="product-item" key={shampoo.name}>
        <a target="_blank" rel="noopener noreferrer" href={shampoo.link}>
          <div>
            <img alt={shampoo.name} src={shampoo.image} />
            <h4>{shampoo.name}</h4>
            <p>{shampoo.description}</p>
          </div>
        </a>
      </div>
    ));
    const conditionerItems = this.props.data.products.conditioners.map(
      (conditioner) => (
        <div className="product-item" key={conditioner.name}>
          <a target="_blank" rel="noopener noreferrer" href={conditioner.link}>
            <div>
              <img alt={conditioner.name} src={conditioner.image} />
              <h4>{conditioner.name}</h4>
              <p>{conditioner.description}</p>
            </div>
          </a>
        </div>
      )
    );
    const additionalItems = this.props.data.products.additional.map(
      (additional) => (
        <div className="product-item" key={additional.name}>
          <a target="_blank" rel="noopener noreferrer" href={additional.link}>
            <div>
              <h4>{additional.name}</h4>
              <p>{additional.description}</p>
            </div>
          </a>
        </div>
      )
    );
    const lifestyleNormalGoals = this.props.data.lifestyle.normal_goals.map(
      (normal_goal) => (
        <div key={normal_goal.goal}>
          <div>
            <h3>{normal_goal.goal}</h3>
            <p>{normal_goal.response}</p>
          </div>
        </div>
      )
    );
    const lifestyleCustomGoals = this.props.data.lifestyle.custom_goals.map(
      (custom_goal) => (
        <div key={custom_goal.goal}>
          <div>
            <h3>{custom_goal.goal}</h3>
            <p>{custom_goal.response}</p>
          </div>
        </div>
      )
    );
    const insights = this.props.data.lifestyle.insights.map((insight) => (
      <div key={insight.insight}>
        <div>
          <h3>{insight.insight}</h3>
          <p>{insight.response}</p>
        </div>
      </div>
    ));

    const dialImages = this.state.dials.map((dialImagesURL) => (
      <div key={dialImagesURL}>
        <img alt="Dials" src={dialImagesURL} />
      </div>
    ));

    return (
      <Tabs>
        <TabList>
          <Tab>Welcome</Tab>
          <Tab>Product Recommendations</Tab>
          <Tab>Lifestyle Recommendations</Tab>
          <Tab>Under the Microscope</Tab>
          <Tab>Facts and Figures</Tab>
        </TabList>

        <TabPanel>
          <div className="tab-panel welcome">
            <div className="header-images-container">
              <img
                src={tab_background}
                alt="water reflecting sunlight"
                className="background-image"
              />
              <img
                src={welcome_foreground}
                alt="woman in swimsuit walking to the ocean with a surfboard"
                className="foreground-image"
              />
            </div>
            <div className="tab-panel-content">
              <h2>Congratulations, {this.props.data.fname}!</h2>
              <div className="centered-text-container">
                <p>
                  Your personalized hair analysis was a success! Click through
                  the tabs for your custom recommendations and insights.
                </p>
              </div>
              <div className="divider"></div>
              <div>
                <div className="centered-text-container">
                  <p>
                    <em>In this report you will find:</em>
                  </p>
                </div>
                <ul>
                  <li>
                    Product recommendations based on your personal hair goals
                  </li>
                  <li>
                    Lifestyle recommendations that will complement your product
                    regimen
                  </li>
                  <li>Images of your hair under the microscope</li>
                  <li>Helpful information about your hair health</li>
                </ul>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tab-panel product-recommendations">
            <div className="header-images-container">
              <img
                src={tab_background}
                alt="water reflecting sunlight"
                className="background-image"
              />
              <img
                src={products_foreground}
                alt="hair products on a countertop"
                className="foreground-image"
              />
            </div>
            <div className="tab-panel-content">
              <h2>Products</h2>
              <div className="centered-text-container">
                <p>
                  We've compiled a personalized set of products to fit your
                  needs.
                </p>
              </div>
              <div className="centered-text-container">
                <p>
                  The choice is yours—select the products that appeal the most
                  to you. Click on the title for each product to learn more.
                </p>
              </div>
              <h3>Shampoos</h3>
              <div className="shampoos product-grid">{shampooItems}</div>
              <h3>Conditioners</h3>
              <div className="conditioners product-grid">
                {conditionerItems}
              </div>
              <h3>Additional Products</h3>
              <div className="additional product-grid">{additionalItems}</div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tab-panel lifestyle-recommendations">
            <div className="header-images-container">
              <img
                src={tab_background}
                alt="water reflecting sunlight"
                className="background-image"
              />
              <img
                src={lifestyle_foreground}
                alt="woman facing away with a comb in her hair"
                className="foreground-image"
              />
            </div>
            <div className="tab-panel-content">
              <h2>Lifestyle</h2>
              <div>
                <div className="centered-text-container">
                  <p>
                    In your survey, you shared some of your personal hair goals.
                  </p>
                </div>
                <div className="centered-text-container">
                  <p>
                    The recommendations below are intended to improve your
                    overall satisfaction with your hair when paired with your
                    recommended product regimen.
                  </p>
                </div>
              </div>
              <div className="lifestyle-recommendations-list">
                <div className="normal-goals">{lifestyleNormalGoals}</div>
                <div className="custom-goals">{lifestyleCustomGoals}</div>
                <div className="insights">{insights}</div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tab-panel under-the-microscope">
            <div className="header-images-container">
              <img
                src={tab_background}
                alt="water reflecting sunlight"
                className="background-image"
              />
              <img
                src={microscope_foreground}
                alt="microscope"
                className="foreground-image"
              />
            </div>
            <div className="tab-panel-content">
              <h2>Under the Microscope</h2>
              <div>
                <div className="centered-text-container">
                  <p>
                    Your hair cuticles were micrographed several times at
                    various lengths along each strand.
                  </p>
                </div>
                <div className="centered-text-container">
                  <p>
                    We've included an illustration of cuticle damage along the
                    length of your hair as well as a few sample micrographs at
                    your root, middle, and end.
                  </p>
                </div>
                {/* if the screen width is less than 768px, then display */}
                {this.state.isMobile ? (
                  <div>
                    <div>
                      <h3>Hair 1</h3>
                      <div className="chart-container">
                        <img
                          className="mobile-hair-health-graph"
                          src={this.state.mobileGraphReference1}
                          alt="hair health graph"
                        />
                      </div>
                      <h3>Hair 2</h3>
                      <div className="chart-container">
                        <img
                          className="mobile-hair-health-graph"
                          src={this.state.mobileGraphReference2}
                          alt="hair health graph"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h3>Hair 1</h3>
                    <div className="chart-container">
                      <img
                        className="desktop-hair-health-graph"
                        src={this.state.desktopGraphReference1}
                        alt="hair health graph"
                      />
                    </div>
                    <h3>Hair 2</h3>
                    <div className="chart-container">
                      <img
                        className="desktop-hair-health-graph"
                        src={this.state.desktopGraphReference2}
                        alt="hair health graph"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tab-panel facts-and-figures">
            <div className="header-images-container">
              <img
                src={tab_background}
                alt="water reflecting sunlight"
                className="background-image"
              />
              <img
                src={facts_foreground}
                alt="laptop on desk with a microscope in the background"
                className="foreground-image"
              />
            </div>
            <div className="tab-panel-content">
              <h2>Facts and Figures</h2>
              <div>
                <p>
                  In this section, we'll give you a crash course on hair
                  anatomy, then describe what we learned about your hair's
                  health based on your survey responses and our micrograph
                  analysis.
                </p>
              </div>
              <h3>Hair Anatomy</h3>
              <div className="hair-anatomy">
                <p>
                  The diagram below shows the 3 main parts of a hair: the
                  cuticle, the cortex, and the medulla.
                </p>
                <div>
                  <img alt="Hair Anatomy Diagram" src={hair_anatomy_diagram} />
                </div>
                <dl>
                  <dt>Cuticle</dt>
                  <dd>
                    The outermost layer of the hair, made up of many overlapping
                    cells. Its purpose is to protect the cortex, just like the
                    insulation on an electrical cable or the bark on a tree.
                    This is the main structure that we analyze under the
                    microscope to determine the integrity of your hair.
                  </dd>
                  <dt>Cortex</dt>
                  <dd>
                    The middle layer that takes up most of the space in the
                    hair. It provides the strength, color, and texture of a hair
                    strand. The fibrous protein keratin is responsible for
                    forming the cortex.
                  </dd>
                  <dt>Medulla</dt>
                  <dd>
                    The fragile, central core of the hair strand. It is usually
                    only present in thicker hair strands and can influence hair
                    color.
                  </dd>
                </dl>
              </div>
              <div className="why-it-matters">
                <div>
                  <p>
                    As long as the cuticle is intact, your hair can grow
                    indeterminately long. However, as the cuticle wears away,
                    the cortex is exposed and can easily break. The purpose of
                    most hair regimens is to protect and restore cuticle health
                    in order to prevent breakage.
                  </p>
                </div>
              </div>
              <h3>Your Results</h3>
              <div className="your-results">
                <div className="dial-images">{dialImages}</div>
                <div>
                  <p>
                    The arches above show how your hair scored compared to the
                    average scores of first-time Bellu clients (shown by the
                    gray dotted line). Arches shaded gray represent hair health
                    categories that may require extra attention.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    );
  }
}

export default StandardReport;
