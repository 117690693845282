import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../css/StarterReport.css";
import { ServerFacade } from "../service/ServerFacade";
import tab_background from "../images/report_images/report_background_364.jpg";
import welcome_foreground from "../images/report_images/welcome_foreground.jpg";
import products_foreground from "../images/report_images/products_foreground.jpg";

/**
 * StarterReport is a component that displays the standard report for a user.
 * It is composed of 5 tabs: Welcome, Product Recommendations, Lifestyle Recommendations,
 * Under the Microscope, and Facts and Figures.
 */
class StarterReport extends Component {
  constructor(props) {
    super(props);
    this.isInitialMount = true;
    this.state = {
      dials: [],
      desktopGraphReference1: "",
      desktopGraphReference2: "",
      mobileGraphReference1: "",
      mobileGraphReference2: "",
      graphError: false,
      isMobile: false,
    };
  }

  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  async componentDidMount() {
    // set event listener for screen size
    if (this.isInitialMount) {
      window.addEventListener("resize", this.handleResize);
    }
    this.handleResize();
    this.isInitialMount = false;
    let serverFacade = new ServerFacade();
    let tempDials = [];
    let NUM_DIALS = 6;
    try {
      // get dials
      for (let i = 1; i <= NUM_DIALS; i++) {
        await serverFacade
          .getReportImageURL(
            this.props.timestamp,
            this.props.version,
            "dials",
            i
          )
          .then((data) => {
            if (data) {
              if (data.success === false) {
              } else {
                tempDials.push(data.url);
              }
              this.setState({ dials: tempDials });
            }
          });
      }
      // get graphs
      let graphURLs = [];
      for (let i = 1; i <= 4; i++) {
        graphURLs.push(await this.getGraphURL(i));
      }
      if (graphURLs.length === 4) {
        this.setState({
          desktopGraphReference1: graphURLs[0],
          desktopGraphReference2: graphURLs[1],
          mobileGraphReference1: graphURLs[2],
          mobileGraphReference2: graphURLs[3],
        });
      } else {
        this.setState({ graphError: true });
      }
    } catch (error) {}
  }

  getGraphURL = async (graphNumber) => {
    let serverFacade = new ServerFacade();
    let tempStrandGraph = "";
    await serverFacade
      .getReportImageURL(
        this.props.timestamp,
        this.props.version,
        "microscope",
        graphNumber
      )
      .then((data) => {
        if (data) {
          if (data.success === false) {
          } else {
            tempStrandGraph = data.url;
          }
        }
      });
    return tempStrandGraph;
  };

  render() {
    const shampooItems = this.props.data.products.shampoos.map((shampoo) => (
      <div className="product-item" key={shampoo.name}>
        <a target="_blank" rel="noopener noreferrer" href={shampoo.link}>
          <div>
            <img alt={shampoo.name} src={shampoo.image} />
            <h4>{shampoo.name}</h4>
            <p>{shampoo.description}</p>
          </div>
        </a>
      </div>
    ));
    const conditionerItems = this.props.data.products.conditioners.map(
      (conditioner) => (
        <div className="product-item" key={conditioner.name}>
          <a target="_blank" rel="noopener noreferrer" href={conditioner.link}>
            <div>
              <img alt={conditioner.name} src={conditioner.image} />
              <h4>{conditioner.name}</h4>
              <p>{conditioner.description}</p>
            </div>
          </a>
        </div>
      )
    );

    return (
      <Tabs>
        <TabList>
          <Tab>Welcome</Tab>
          <Tab>Product Recommendations</Tab>
        </TabList>

        <TabPanel>
          <div className="tab-panel welcome">
            <div className="header-images-container">
              <img
                src={tab_background}
                alt="water reflecting sunlight"
                className="background-image"
              />
              <img
                src={welcome_foreground}
                alt="woman in swimsuit walking to the ocean with a surfboard"
                className="foreground-image"
              />
            </div>
            <div className="tab-panel-content">
              <h2>Congratulations, {this.props.data.fname}!</h2>
              <div className="centered-text-container">
                <p>
                  Your personalized hair analysis was a success! Click through
                  the tabs for your custom recommendations and insights.
                </p>
              </div>
              <div className="divider"></div>
              <div>
                <div className="centered-text-container">
                  <p>
                    <em>In this report you will find:</em>
                  </p>
                </div>
                <ul>
                  <li>
                    Product recommendations based on your personal hair goals
                  </li>
                  <li>
                    Lifestyle recommendations that will complement your product
                    regimen
                  </li>
                  <li>Images of your hair under the microscope</li>
                  <li>Helpful information about your hair health</li>
                </ul>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tab-panel product-recommendations">
            <div className="header-images-container">
              <img
                src={tab_background}
                alt="water reflecting sunlight"
                className="background-image"
              />
              <img
                src={products_foreground}
                alt="hair products on a countertop"
                className="foreground-image"
              />
            </div>
            <div className="tab-panel-content">
              <h2>Products</h2>
              <div className="centered-text-container">
                <p>
                  We've compiled a personalized set of products to fit your
                  needs.
                </p>
              </div>
              <div className="centered-text-container">
                <p>
                  The choice is yours—select the products that appeal the most
                  to you. Click on the title for each product to learn more.
                </p>
              </div>
              <h3>Shampoos</h3>
              <div className="shampoos product-grid">{shampooItems}</div>
              <h3>Conditioners</h3>
              <div className="conditioners product-grid">
                {conditionerItems}
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    );
  }
}

export default StarterReport;
