import React, { Component } from "react";
import { ServerFacade } from "../service/ServerFacade";
import { Loader } from "./Loader";
import right_arrow from "../images/right_arrow.png";
import "../css/ReportList.css";

/**
 * Component that displays a list of reports. The list is loaded from the server
 * in pages of 10 reports. The list is updated when the user scrolls to the bottom
 * of the page.
 */
class ReportsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      lastTimestamp: 0,
      hasMorePages: true,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadReports(true);

    // add event listener for when page scrolls
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  /**
   * Fetches reports from the server and updates the state with the new reports. Uses
   * the last timestamp of the last report as a parameter to the server to get the next
   * page of reports. If the server returns no reports, the hasMorePages state is set to
   * false to prevent further requests to the server.
   */
  loadReports = async (initialLoad) => {
    if (this.state.hasMorePages) {
      this.setState({ isLoading: true });
      let responseLastTimestamp = this.state.lastTimestamp;

      try {
        let serverFacade = new ServerFacade();
        const response = await serverFacade.getReports(responseLastTimestamp);
        const newReports = response.reportDetails;

        if (newReports.length > 0) {
          responseLastTimestamp = newReports[newReports.length - 1].timestamp;
          let updatedReports = [];
          if (!initialLoad) {
            updatedReports = [...this.state.reports, ...newReports];
            this.setState({
              reports: updatedReports,
              lastTimestamp: responseLastTimestamp,
              hasMorePages: response.hasMorePages,
              isLoading: false,
            });
          } else {
            updatedReports = newReports;
            this.setState({
              reports: updatedReports,
              lastTimestamp: responseLastTimestamp,
              hasMorePages: response.hasMorePages,
              isLoading: false,
            });
          }
          // fixme: for testing
          // setTimeout(() => {
          //   this.props.onReportClick(this.state.reports[0]);
          // }, 100);
          // end fixme
        } else {
          this.setState({ isLoading: false });
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
        this.setState({ isLoading: false });
      }
    }
  };

  /**
   * Checks if the user has scrolled to the bottom of the page and loads more reports
   * if they have.
   */
  handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 ||
      window.innerHeight >= document.body.offsetHeight
    ) {
      if (!this.state.isLoading) {
        this.loadReports(false);
      }
    }
  };

  handleItemClick = (report) => {
    let serverFacade = new ServerFacade();
    if (!report.opened) {
      serverFacade.setReportOpened(report.timestamp);
    }

    this.props.onReportClick(report);
  };

  timestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US");
  };

  render() {
    const { reports, isLoading } = this.state;
    return (
      <div className="center">
        <div className="report-list-container">
          <h1 className="report-list-heading">My Hair Test Results</h1>
          <ul className="report-list">
            {reports.map((report, index) => (
              <li
                key={index}
                className="report-item"
                onClick={function() {
                  this.handleItemClick(report);
                }.bind(this)}
              >
                <div>
                  <div>{this.timestampToDate(report.timestamp)}</div>
                  <div className="spacer"></div>
                  {!report.opened && (
                    <div className="unopened-pill-notification-container">NEW</div>
                  )}
                  <div>
                    <img src={right_arrow} alt="right arrow" />
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {isLoading && (
            <div className="center">
              <Loader />
            </div>
          )}
          {!isLoading && reports.length === 0 && (
            <div className="no-reports">
              Your report isn't ready yet. If you've already received your
              confirmation email, please contact websupport@bellu.com.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ReportsList;
