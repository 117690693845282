import React, { Component } from "react";
import { ServerFacade } from "../service/ServerFacade";
import { Loader } from "./Loader";
import ReportWithMenu from "./ReportWithMenu";

class ReportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      errorMessage: "",
      data: [],
    };
  }

  componentDidMount() {
    let serverFacade = new ServerFacade();
    try {
      serverFacade.getReportURL(this.props.timestamp, this.props.email).then((data) => {
        // console.log(data);
        if (data.success === false) {
          this.setState({
            error: true,
            errorMessage: data.message,
            loading: false,
          });
        } else {
          // success
          fetch(data.url)
            .then((response) => response.json())
            .then((data) => this.setState({ data: data }))
            .then(() => {
              this.setState({ loading: false });
            });
        }
      });
    } catch (error) {
      this.setState({
        error: true,
        errorMessage: error.message,
        loading: false,
      });
    }
  }

  handleReportClick = (report) => {
    // Do something with the clicked report index
    this.setState({ focusedReport: report });
    // console.log("focusedReport: " + report);
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="center">
          <Loader />
        </div>
      );
    } else if (this.state.error) {
      return <div>{this.state.errorMessage}</div>;
    } else {
      return (
        <ReportWithMenu
          data={this.state.data}
          email={this.props.email}
          timestamp={this.props.timestamp}
          version={this.props.version}
          returnToReports={this.props.returnToReports}
        />
      );
    }
  }
}

export default ReportContainer;
