import React, { Component } from "react";
import ReportsList from "../components/ReportsList";
import black_logo from "../images/black_logo.png";
import ReportContainer from "../components/ReportContainer";
import "../css/ReportsView.css";

class ReportsView extends Component {
  componentDidMount() {
    this.props.setReportIndex(-1);
  }

  handleReportClick = (report) => {
    this.props.setReportIndex(report);
  };

  handleBackClick = () => {
    this.props.setReportIndex(-1);
  };

  render() {
    if (this.props.focusedReport !== -1) {
      return (
        <div className="report-display-container">
          <ReportContainer
            email={this.props.focusedReport.email}
            timestamp={this.props.focusedReport.timestamp}
            version={this.props.focusedReport.version}
            returnToReports={this.handleBackClick}
          />
        </div>
      );
    } else {
      return (
        <div>
          <ReportsList onReportClick={this.handleReportClick} />
          <div className="center">
            <img
              className="report-list-footer center"
              src={black_logo}
              alt="logo"
            />
          </div>
        </div>
      );
    }
  }
}

export default ReportsView;
